const exercises = [
  { name: "Torso Twists", targetArea: "abs", difficulty: "beginner", linkValue: "torso twists exercise" },
  { name: "Plank Jacks", targetArea: "cardio", difficulty: "intermediate", linkValue: "plank jack alternatives" },
  { name: "Jump Squats", targetArea: "legs", difficulty: "intermediate", linkValue: "jump squat alternatives" },
  { name: "Burpees", targetArea: "full body", difficulty: "advanced", linkValue: "burpee alternatives" },
  { name: "Russian Twists", targetArea: "abs", difficulty: "intermediate", linkValue: "russian twists exercise" },
  { name: "Plank", targetArea: "abs", difficulty: "beginner", linkValue: "plank alternatives" },
  { name: "Leg Raises", targetArea: "abs", difficulty: "beginner", linkValue: "leg raise exercise" },
  { name: "Bicycle Crunches", targetArea: "abs", difficulty: "intermediate", linkValue: "bicycle crunch exercise" },
  { name: "Calf Raises", targetArea: "legs", difficulty: "beginner", linkValue: "calf raise exercise" },
  { name: "Side Lunges", targetArea: "legs", difficulty: "intermediate", linkValue: "side lunges exercise" },
  { name: "Glute Bridges", targetArea: "legs", difficulty: "beginner", linkValue: "glute bridge exercise" },
  { name: "Lunges", targetArea: "legs", difficulty: "beginner", linkValue: "lunge exercise" },
  { name: "Squats", targetArea: "legs", difficulty: "beginner", linkValue: "squat alternatives" },
  { name: "Tricep Dips", targetArea: "arms", difficulty: "intermediate", linkValue: "tricep dip alternatives" },
  { name: "Shoulder Taps", targetArea: "arms", difficulty: "beginner", linkValue: "shoulder tap exercise" },
  { name: "Push-ups", targetArea: "arms", difficulty: "intermediate", linkValue: "pushup alternatives" },
  { name: "Mountain Climbers", targetArea: "cardio", difficulty: "intermediate", linkValue: "mountain climber alternatives" },
  { name: "High Knees", targetArea: "cardio", difficulty: "beginner", linkValue: "high knees exercise" },
  { name: "Jumping Jacks", targetArea: "cardio", difficulty: "beginner", linkValue: "jumping jacks exercise" },
  { name: "Superman Raises", targetArea: "full body", difficulty: "beginner", linkValue: "superman raises exercise" },
  { name: "Inchworms", targetArea: "full body", difficulty: "intermediate", linkValue: "inchworm exercise" },
  { name: "Plank with Arm Lift", targetArea: "abs", difficulty: "intermediate", linkValue: "plank with arm lift" },
  { name: "Small Arm Circles", targetArea: "arms", difficulty: "beginner", linkValue: "small arm circles exercise" },
  { name: "Wall Sit", targetArea: "legs", difficulty: "beginner", linkValue: "wall sit exercise" },
  { name: "Toe Touches", targetArea: "abs", difficulty: "beginner", linkValue: "toe touch exercise" },
  { name: "Flutter Kicks", targetArea: "abs", difficulty: "intermediate", linkValue: "flutter kicks exercise" }
];

let todaysWorkout = generateTodaysWorkout();

// Function to group exercises by target area
function groupByTargetArea(exercises) {
  return exercises.reduce((groups, exercise) => {
    const { targetArea } = exercise;
    if (!groups[targetArea]) {
      groups[targetArea] = [];
    }
    groups[targetArea].push(exercise);
    return groups;
  }, {});
}

// Function to get a consistent random number generator based on the date and offset
function seededRandom(seed, offset = 0) {
  const x = Math.sin(seed + offset) * 10000;
  return x - Math.floor(x);
}

function generateTodaysWorkout() {
  const groupedExercises = groupByTargetArea(exercises);

  const targetAreas = Object.keys(groupedExercises);

  const today = new Date();
  const seed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate(); // Unique seed based on date

  const workout = targetAreas.map((targetArea, index) => {
    const exercisesForArea = groupedExercises[targetArea];
    const randomIndex = Math.floor(seededRandom(seed, index) * exercisesForArea.length);
    return exercisesForArea[randomIndex];
  });

  return workout;
}


// Function to get today's workout, no input required
export function getTodaysWorkout() {
  return todaysWorkout;
}

export function doubleTodaysWorkout() {
  todaysWorkout = [...todaysWorkout, ...todaysWorkout];  // Concatenates the array with itself
  console.log('Workout list doubled:', todaysWorkout);

}
